import React from 'react';
import Layout from "../../components/layout";

const Bagamoyo = () => {
  return (
    <Layout>
      <h1>Bagamoyo</h1>
      <h2>Instructors</h2>
      <p>Senpai Mohammed Kivuli</p>

      <p><strong>Class Times</strong><br/>
        <strong>Monday to Friday:</strong> 1700hrs to 2000hrs.
      </p>

      <p><strong>Price</strong><br/>
        Tshs 5000/- monthly to be paid before beginning of the month. (1 USD is equal to approx. 1500/- Tshs.)
      </p>

      <p><strong>Location</strong><br/>
        70km from Dar City. North of Dar City. At CCM Hall adjacent to Bagamoyo Town Council Offices and other side is
        Bagamoyo Primary Court.</p>
    </Layout>
  );
}

export default Bagamoyo;
